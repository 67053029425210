import { UserComments } from 'views/admin/users/components/ModerationBlock';
import { IUserEventsList } from 'shared/model/offers';

import { IOffer, IUser } from '../model';
import { Caste, Gender, IDeletedUsersList, IUsersList, ProfileStatus, SearchStatus } from '../model/user';

import { apiInstance } from './base';

export interface IUsersListRequest {
  page?: number;
  limit?: number; // default: 20
  userId?: string;
  email?: string;
  locationCity?: string;
  locationCountry?: string;
  profileStatus?: ProfileStatus;
  profileCreatedAtFrom?: string;
  profileCreatedAtTo?: string;
  searcherStatus?: SearchStatus;
  ageFrom?: number;
  ageTo?: number;
  gender?: Gender;
  caste?: Caste;
  //TODO: add exact values
  sortBy?: string;
  sortOrder?: 'asc' | 'desc';
  cityBorn?: string;
}

export interface IDeletedUsersListFilter {
  page?: number;
  limit?: number; // default: 20
}

/**
 * Fetches a user by their ID from the server.
 *
 * @param {number} id - The ID of the user.
 * @return {Promise<User>} A promise that resolves to the user data.
 */
export async function fetchUser(id: number): Promise<IUser> {
  const response = await apiInstance.get(`/api/v1/admin/users/${id}`);

  return response.data;
}

/**
 * Fetches users based on the provided filter.
 *
 * @param {UsersListRequest} filter - The filter to apply to the user fetch request.
 * @return {Promise<UsersList>} - A promise that resolves to the fetched user data.
 */
export async function fetchUsers(filter: IUsersListRequest): Promise<IUsersList> {
  const response = await apiInstance.get('/api/v1/admin/users', {
    params: { ...filter, limit: filter.limit },
  });

  return response.data;
}

export async function fetchOffer(id: number): Promise<IOffer> {
  const response = await apiInstance.get(`/api/v1/admin/offers/${id}`);

  return response.data;
}

export const approveUser = async (id: number) => {
  const response = await apiInstance.put(`/api/v1/admin/users/${id}/moderate`);

  return response.data;
};

export const rejectUser = async (id: number) => {
  const response = await apiInstance.put(`/api/v1/admin/users/${id}/reject`);

  return response.data;
};

export const setUserComments = async (id: number, comments: UserComments) => {
  const response = await apiInstance.post(`/api/v1/admin/users/${id}/warning-notification`, { ...comments });

  return response.data;
};

export const deletePhoto = async (id: string) => {
  const response = await apiInstance.delete(`api/v1/admin/photos/${id}`);

  return response.data;
};

export const editBio = async (id: number, bio: string) => {
  const response = await apiInstance.put(`api/v1/admin/users/${id}/bio`, { bio });

  return response.data;
};

export const editFirstName = async (id: number, firstName: string) => {
  const response = await apiInstance.post(`api/v1/admin/users/${id}/first-name`, { firstName });

  return response.data;
};

export const editCaste = async (id: number, caste: string) => {
  const response = await apiInstance.put(`api/v1/admin/users/${id}/caste`, { caste });

  return response.data;
};

export const editGender = async (id: number, gender: string) => {
  const response = await apiInstance.put(`api/v1/admin/users/${id}/gender`, { gender });

  return response.data;
};

export const getUserEvents = async (id: number): Promise<IUserEventsList> => {
  const response = await apiInstance.get(`api/v1/admin/users/${id}/events`);

  return response.data;
};

export const setUserUnban = async (id: number): Promise<void> => {
  const response = await apiInstance.put(`api/v1/admin/users/${id}/shadow-ban-off`);

  return response.data;
};

export const fetchDeletedUsers = async (filter: IDeletedUsersListFilter): Promise<IDeletedUsersList> => {
  const response = await apiInstance.get('/api/v1/admin/deletions', {
    params: { ...filter, limit: filter.limit },
  });

  return response.data;
};

/*      PHP code

        $userId = $filter['userId'] ?? null;
        $accountEmail = $filter['email'] ?? null;
        $locationCity = $filter['locationCity'] ?? '';
        // $currentWeek = $this->getCurrentWeek();

        $profileStatus = $filter['profileStatus'] ?? '';

        // $profileCreatedAtFrom = $request['profileCreatedAtFrom'] ?? $currentWeek['createdAtFrom']; // todo ?
        // $profileCreatedAtTo = $request['profileCreatedAtTo'] ?? $currentWeek['createdAtTo']; // todo ?
        $profileCreatedAtFrom = $filter['profileCreatedAtFrom'] ?? null;
        $profileCreatedAtTo = $filter['profileCreatedAtTo'] ?? null;

        $searcherStatus = $filter['searcherStatus'] ?? '';
        $searcherAgeFrom = $filter['ageFrom'] ?? null;
        $searcherAgeTo = $filter['ageTo'] ?? null;
        $searcherGender = $filter['gender'] ?? null;
        $searcherCaste = $filter['caste'] ?? null;
 */
