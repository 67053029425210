/*

   TODO: may bu use this: https://codesandbox.io/p/sandbox/react-photo-gallery-with-image-viewer-forked-ftd2jw?file=%2Findex.js%3A21%2C11
   (https://github.com/neptunian/react-photo-gallery)



 */

import { Alert, AlertIcon, AlertDescription, AspectRatio, Box, Heading, Image, SimpleGrid } from '@chakra-ui/react';
import { sortBy } from 'lodash';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import Card from 'components/card/Card';
import { ICustomCardProps } from 'theme/theme';

import PhotosWithLightbox, { PhotoItem } from '../../../../shared/ui/PhotosWithLightbox';
import { IPhoto } from '../../../../shared/model/user';

type UserPhotoBlock = {
  photos: IPhoto[];
  biometric: IPhoto;
  examination: IPhoto;
} & Partial<ICustomCardProps>;

export default function UserPhotoBlock({ photos, biometric, examination, ...rest }: UserPhotoBlock) {
  /*
      
      const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
      const textColorSecondary = 'gray.400';
      const cardShadow = useColorModeValue('0px 18px 40px rgba(112, 144, 176, 0.12)', 'unset');
    */

  if (!photos?.length) {
    return (
      <Box>
        <Alert status="info" variant="subtle" borderRadius="md" mb={4}>
          <AlertIcon />
          <AlertDescription>Фото еще не загружены.</AlertDescription>
        </Alert>
      </Box>
    )
  }

  const photoItems: PhotoItem[] = sortBy(photos, ['position']).map(photo => ({
    src: photo.image,
    id: photo.id.toString(),
    status: photo.status.toString(),
  }));

  const hasPhotoPending = photoItems.some(photo => photo.status === 'pending');

  return (
    <Card
      mb={{
        base: '0px',
        '2xl': '20px',
      }}
      {...rest}
    >
      <SimpleGrid columns={2}>
        <Box pb={4} w="100%" pr={1}>
          <Heading as="h5" size="sm" pb={2}>
            Контрольное фото
          </Heading>
          <AspectRatio ratio={3 / 3} w="100%">
            <Image
              src={biometric?.image}
              fallbackSrc="/assets/img/no-image.png"
              w="100%"
              objectFit="cover"
            />
          </AspectRatio>
        </Box>
        <Box w="100%" maxH="33%" pl={1}>
          <Heading as="h5" size="sm" pb={2}>
            Образец
          </Heading>
          <AspectRatio ratio={3 / 3} w="100%">
            <Image src={examination?.image} fallbackSrc="/assets/img/no-image.png" w="100%" objectFit="cover" />
          </AspectRatio>
        </Box>
      </SimpleGrid>

      <Box position={'relative'} height={'auto'} width={'full'} overflow={'hidden'} objectFit="cover">
        {hasPhotoPending && (
          <Alert status="warning" variant="subtle" borderRadius="md" mb={4}>
            <AlertIcon />
            <AlertDescription>Есть фото на модерации.</AlertDescription>
          </Alert>
        )}
        <PhotosWithLightbox images={photoItems} variant="carousel" />
      </Box>
    </Card>
  );
}
