import * as React from 'react';

import { ComponentWithAs, HTMLChakraProps, Icon, IconProps, useColorModeValue } from '@chakra-ui/react';
import { IconType } from 'react-icons';
import { MdOutlineQuestionMark } from 'react-icons/md';

import { AppleLogo, AndroidLogo } from '../../../components/icons/Icons';

type PlatformIcon = {
  os: 'apple' | 'google' | 'ios' | 'android';
} & Omit<HTMLChakraProps<'svg'>, 'orientation'>;

export const PlatformIcon = ({ os, boxSize, color, ...rest }: PlatformIcon) => {
  const platformIconColor = useColorModeValue('gray.400', 'gray.400');
  const size = 5;
  let icon: IconType | ComponentWithAs<'svg', IconProps>;
  const additionalProps: { pt?: number } = {};
  switch (os) {
    case 'apple':
    case 'ios':
      icon = AppleLogo;
      break;
    case 'google':
    case 'android':
      icon = AndroidLogo;
      break;
    default:
      icon = MdOutlineQuestionMark;
      additionalProps.pt = 2;
  }

  return (
    <Icon as={icon} boxSize={boxSize || size} color={color || platformIconColor} {...additionalProps} {...rest} />
  );
};
