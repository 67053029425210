import React, { ReactNode, useState } from 'react';

import { Box, Button, Text, Textarea, useColorModeValue, Radio, RadioGroup, Stack } from '@chakra-ui/react';
import { CheckIcon, EditIcon, CloseIcon } from '@chakra-ui/icons';

import { ICustomCardProps } from 'theme/theme';
import Card from 'components/card/Card';

import { IIdNameCode } from '../../../../shared/model';

type InformationVariants = 'default' | 'searcher' | 'vedic';

type InformationRadioOption = {
  value: string;
  label?: string;
};

type InformationProps = {
  title: string;
  variant?: InformationVariants;
  value: number | string | ReactNode | IIdNameCode | IIdNameCode[];
  valueBioDraft?: string;
  valueAlign?: 'right' | 'left' | 'center';
  isEditable?: boolean;
  editCallback?: (value: string) => void;
  radioOptions?: InformationRadioOption[] | string[];
} & Partial<Omit<ICustomCardProps, 'variant'>>;

export default function Information({
  title,
  value,
  valueBioDraft, 
  variant = 'default',
  valueAlign = 'left',
  isEditable,
  editCallback,
  radioOptions = [],
  ...rest
}: InformationProps) {
  const [isEditing, setIsEditing] = useState(false);
  const [isSend, setIsSend] = useState(false);
  const [editableValue, setEditableValue] = useState((value || '-').toString());
  const colors = {
    default: useColorModeValue('white', 'navy.700'),
    searcher: useColorModeValue('cyan.50', 'navy.700'),
    vedic: useColorModeValue('purple.50', 'navy.700'),
  };
  const cardShadow = useColorModeValue('0px 18px 40px rgba(112, 144, 176, 0.12)', 'unset');

  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const textColorSecondary = 'gray.400';
  // const bg = useColorModeValue('white', 'navy.700');

  let displayValue: string | ReactNode = null;

  const handleEditClick = () => {
    if (isEditing && editCallback && (value || value?.toString() !== editableValue)) {
      editCallback(editableValue);
      setIsSend(true);
    }
    
    if (!isEditing && valueBioDraft && valueBioDraft !== value) {
      setEditableValue(valueBioDraft);
    }
  
    setIsEditing(!isEditing);
  };
  
  const handleCanselClick = () => {
    setIsEditing(!isEditing);
    setEditableValue((value || '-').toString());
  };

  const editValue = (newValue: string) => setEditableValue(newValue);

  if (value) {
    if (Array.isArray(value)) {
      if (value.length > 0) {
        if (value.every(v => Object.prototype.hasOwnProperty.call(v, 'name'))) {
          value.map(v => v.name).join(', ');
        } else {
          displayValue = value.join(', ');
        }
      } else {
        displayValue = null;
      }
    } else {
      if (React.isValidElement(value)) {
        displayValue = value;
      } else {
        displayValue =
          typeof value === 'object' && value !== null && 'name' in value ? value.name : (value as ReactNode);
      }
    }
  }

  return (
    <Card bg={colors[variant]} boxShadow={cardShadow} {...rest}>
      <Box>
        <Box display="flex" justifyContent="space-between" alignItems="center" fontWeight="500" color={textColorSecondary} fontSize="sm">
          <Text>
            {title}
          </Text>
          {isEditable && (
            <Box>
              <Button onClick={handleEditClick} colorScheme='teal' size='sm' variant='ghost'>
                {isEditing ? <CheckIcon /> : <EditIcon />}
              </Button>
              {isEditing ? <Button onClick={handleCanselClick} colorScheme='red' size='sm' variant='ghost'>
                <CloseIcon />
              </Button> : ''}
            </Box>
          )}
        </Box>
        <Box color={textColorPrimary} fontWeight="500" fontSize="md" textAlign={valueAlign} mt={2}>
          {isEditable && isEditing ? (
            Array.isArray(radioOptions) && radioOptions.length > 0 ? (
              <RadioGroup value={editableValue} onChange={editValue}>
                <Stack spacing={2} direction="column">
                  {radioOptions.map(option => {
                    const value = typeof option === "string" ? option : option.value;
                    const label = typeof option === "string" ? option : option.label || option.value;
                    
                    return (
                      <Radio key={value} value={value}>
                        {label}
                      </Radio>
                    );
                  })}
                </Stack>
              </RadioGroup>
            ) : (
              <Textarea rows={5} value={editableValue} onChange={e => editValue(e.target.value)} />
            )
          ) : isEditable ? (
            <Box>
              {!isSend && !isEditing && valueBioDraft && valueBioDraft !== value ? (
                <>
                  <Box textDecoration="line-through" color="red.400" mb={4}>
                    {editableValue}
                  </Box>
                  <Box>{valueBioDraft}</Box>
                </>
              ) : (
                <Box>{editableValue}</Box>
              )}
            </Box>
          ) : (
            displayValue || '-'
          )}
        </Box>
      </Box>
    </Card>
  );
}
