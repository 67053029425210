import * as React from 'react';
import { useEffect } from 'react';

import {
  Badge,
  Box,
  Divider,
  Flex,
  IconButton,
  Select,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from '@chakra-ui/react';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import {
  MdOutlineKeyboardDoubleArrowLeft,
  MdOutlineKeyboardDoubleArrowRight,
  MdOutlineKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight,
} from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ChevronDownIcon } from '@chakra-ui/icons';

import { Gender, IUsersList, IUsersListItem } from 'shared/model/user';
import Card from 'components/card/Card';

import { formatDate, formatTime } from '../../../../shared/utils';
import { GenderIcon } from '../../../../shared/ui/GenderIcon';
import { PlatformIcon } from '../../../../shared/ui/PlatformIcon';
import { StatusSearchIcon } from '../../../../shared/ui/StatusSearchIcon';
import TableLoader from '../../../../shared/ui/Table/TableLoader';
import TableNoData from '../../../../shared/ui/Table/TableNoData';

const columnHelper = createColumnHelper<IUsersListItem>();

interface IUsersTableProps {
  tableData: IUsersList;
  loading: boolean;
  error: boolean;
  changePage: (page: number) => void;
  changePerPage: (perPage: number) => void;
  perPage: number;
  navigateOnClickTo?: 'users' | 'users/moderation';
  setSorting: (sortingField: string) => void;
  sortingField: string;
  sortingOrder: 'asc' | 'desc';
}

export default function UsersTable({
  tableData,
  loading,
  error,
  changePage,
  changePerPage,
  perPage,
  navigateOnClickTo = 'users',
  setSorting,
  sortingField,
  sortingOrder,
}: IUsersTableProps) {
  const textColor = useColorModeValue('secondaryGray.900', 'gray.300');

  const rowColors = {
    male: useColorModeValue('cyan.50', 'cyan.900/33'),
    maleHover: useColorModeValue('cyan.100', 'cyan.900/66'),
    female: useColorModeValue('pink.50', 'pink.900/66'),
    femaleHover: useColorModeValue('pink.100', 'pink.900/99'),
  };

  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
  const { t } = useTranslation('translation');
  const [data, setData] = React.useState<IUsersListItem[]>([...(tableData.items || [])]);
  const navigate = useNavigate();

  useEffect(() => {
    setData([...(tableData.items || [])]);
  }, [tableData.items]);

  const navigateToUser = (id: number) => {
    navigate(`/${navigateOnClickTo}/${id}`);
  };

  const handleSorting = (columnId: string) => () => {
    setSorting(columnId);
  };

  const getChevron = (columnName: string) => {
    if (sortingField === columnName) {
      return sortingOrder === 'asc' ? <ChevronDownIcon /> : <ChevronDownIcon transform="rotate(180deg)" />;
    }

    return null;
  };

  const columns = [
    columnHelper.accessor('id', {
      id: 'ID',
      header: () => (
        <Box onClick={handleSorting('id')}>
          <Text
            justifyContent="space-between"
            align="center"
            fontSize={{
              sm: '10px',
              lg: '12px',
            }}
            color="gray.400"
          >
            {t(`user:id`)} {getChevron('id')}
          </Text>
        </Box>
      ),
      cell: info => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),

    /*columnHelper.accessor('os', {
          id: 'os',
          header: () => (
            <Text
              justifyContent='space-between'
              align='center'
              fontSize={{ sm: '10px', lg: '12px' }}
              color='gray.400'>
              {t(`user:os`)}
            </Text>
          ),
          cell: (info) => (
            <Text color={textColor} fontSize='sm' fontWeight='700'>
              {info.getValue()}
            </Text>
          )
        }),*/

    columnHelper.accessor('createdAt', {
      id: 'createdAt',
      header: () => (
        <Box onClick={handleSorting('registeredAt')}>
          <Text
            justifyContent="space-between"
            align="center"
            fontSize={{
              sm: '10px',
              lg: '12px',
            }}
            color="gray.400"
          >
            {t(`user:createdAt`)} {getChevron('registeredAt')}
          </Text>
        </Box>
      ),
      cell: info => {
        return (
          <Flex align="center" direction="row">
            <Text color={textColor} fontSize="sm" fontWeight="700">
              {formatDate(info.getValue())}
            </Text>
            <Text>&nbsp;</Text>
            <Text color="gray.400" fontSize="sm" fontWeight="700">
              {formatTime(info.getValue(), false)}
            </Text>
          </Flex>
        );
      },
    }),

    columnHelper.accessor('os', {
      id: 'os',
      header: () => (
        <Box minInlineSize="36px" onClick={handleSorting('os')}>
          <Text
            justifyContent="space-between"
            fontSize={{
              sm: '10px',
              lg: '12px',
            }}
            color="gray.400"
          >
            {t(`user:os`)} {getChevron('os')}
          </Text>
        </Box>
      ),
      cell: info => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          <PlatformIcon os={info.getValue()} boxSize={6} />
        </Text>
      ),
    }),

    columnHelper.accessor('locationCity', {
      id: 'location_city',
      header: () => (
        <Box onClick={handleSorting('locationCity')}>
          <Text
            justifyContent="space-between"
            align="center"
            fontSize={{
              sm: '10px',
              lg: '12px',
            }}
            color="gray.400"
          >
            {t(`user:locationCity`)} {getChevron('locationCity')}
          </Text>
        </Box>
      ),
      cell: info => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          {info.getValue()}
        </Text>
      ),
    }),
    
    columnHelper.accessor('locationCountry', {
      id: 'location_country',
      header: () => (
        <Box onClick={handleSorting('locationCountry')}>
          <Text
            justifyContent="space-between"
            fontSize={{
              sm: '10px',
              lg: '12px',
            }}
            color="gray.400"
          >
            {t(`user:selectionCountry`)} {getChevron('locationCountry')}
          </Text>
        </Box>
      ),
      cell: info => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          {info.getValue()}
        </Text>
      ),
    }),

    columnHelper.accessor('firstName', {
      id: 'first_name',
      maxSize: 600,
      minSize: 100,
      size: 600,
      header: () => (
        <Box onClick={handleSorting('firstName')}>
          <Text
            justifyContent="space-between"
            align="center"
            fontSize={{
              sm: '10px',
              lg: '12px',
            }}
            color="gray.400"
          >
            {t(`user:first_name`)} {getChevron('firstName')}
          </Text>
        </Box>
      ),
      cell: info => (
        <Text color={textColor} fontSize="sm" fontWeight="700" overflowWrap="anywhere">
          {info.getValue()}
        </Text>
      ),
    }),

    columnHelper.accessor('status', {
      id: 'status',
      header: () => (
        <Box onClick={handleSorting('profileStatus')}>
          <Text
            justifyContent="space-between"
            align="center"
            fontSize={{
              sm: '10px',
              lg: '12px',
            }}
            color="gray.400"
          >
            {t(`user:status`)} {getChevron('profileStatus')}
          </Text>
        </Box>
      ),
      cell: info => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          <Badge variant={info.getValue()}>{t(`user:statuses.${info.getValue()}`)}</Badge>
        </Text>
      ),
    }),

    columnHelper.accessor('statusSearch', {
      id: 'status_search',
      header: () => (
        <Box onClick={handleSorting('searchStatus')}>
          <Text
            justifyContent="space-between"
            align="center"
            fontSize={{
              sm: '10px',
              lg: '12px',
            }}
            color="gray.400"
          >
            {t(`user:status_search`)} {getChevron('searchStatus')}
          </Text>
        </Box>
      ),
      cell: info => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          {/*<Badge variant={info.getValue()}>{t(`user:statuses_search.${info.getValue()}`)}</Badge>*/}
          <StatusSearchIcon searchStatus={info.getValue()} boxSize={6} color={textColor} />
        </Text>
      ),
    }),

    columnHelper.accessor('age', {
      id: 'age',
      header: () => (
        <Box onClick={handleSorting('age')}>
          <Text
            justifyContent="center"
            align="center"
            fontSize={{
              sm: '10px',
              lg: '12px',
            }}
            color="gray.400"
          >
            {t(`user:age`)} {getChevron('age')}
          </Text>
        </Box>
      ),
      cell: info => (
        <Text color={textColor} fontSize="sm" fontWeight="700" align="center">
          {info.getValue()}
        </Text>
      ),
    }),

    columnHelper.accessor('channel', {
      id: 'channel',
      header: () => (
        <Box onClick={handleSorting('channel')}>
          <Text
            justifyContent="center"
            align="center"
            fontSize={{
              sm: '10px',
              lg: '12px',
            }}
            color="gray.400"
          >
            {t(`user:channel`)} {getChevron('channel')}
          </Text>
        </Box>
      ),
      cell: info => (
        <Text color={textColor} fontSize="sm" fontWeight="700" align="center">
          {info.getValue()}
        </Text>
      ),
    }),

    columnHelper.accessor('pugetFillingPercent', {
      id: 'puget_filling_percent',
      header: () => (
        <Box onClick={handleSorting('pugetFillingPercent')}>
          <Text
            justifyContent="space-between"
            align="center"
            fontSize={{
              sm: '10px',
              lg: '12px',
            }}
            color="gray.400"
          >
            {t(`user:puget_filling_percent`)} {getChevron('pugetFillingPercent')}
          </Text>
        </Box>
      ),
      cell: info => (
        <Text color={textColor} fontSize="sm" fontWeight="700" align="center">
          {info.getValue()}
        </Text>
      ),
    }),

    columnHelper.accessor('buyMeetings', {
      id: 'buy_meetings',
      header: () => (
        <Box onClick={handleSorting('purchasesCount')}>
          <Text justifyContent="space-between" align="center" fontSize={{ sm: '10px', lg: '12px' }} color="gray.400">
            {t(`user:meetingsBought`)} {getChevron('purchasesCount')}
          </Text>
        </Box>
      ),
      cell: info => (
        <Text color={textColor} fontSize="sm" fontWeight="700" align="center">
          {info.getValue()}
        </Text>
      ),
    }),

    columnHelper.accessor('gender', {
      id: 'gender',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{
            sm: '10px',
            lg: '12px',
          }}
          color="gray.400"
        >
          {t(`user:gender`)}
        </Text>
      ),
      cell: info => (
        <Text color={textColor} fontSize="sm" fontWeight="700" align="center">
          <GenderIcon gender={info.getValue()} boxSize={6} />
        </Text>
      ),
    }),

    columnHelper.accessor('detailedBalance', {
      id: 'detailedBalance',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{
            sm: '10px',
            lg: '12px',
          }}
          color="gray.400"
        >
          {t(`user:detailedBalance`)}
        </Text>
      ),
      cell: info => (
        <Text color={textColor} fontSize="sm" fontWeight="700" align="center">
          {info.getValue()}
        </Text>
      ),
    }),
  ];

  const table = useReactTable({
    data,
    columns,
    state: {
      columnVisibility: { gender: false },
    },
    enableSorting: false,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  if (error) {
    return (
      <Flex justify="center" py={5}>
        <Text>Error</Text>
      </Flex>
    );
  }

  return (
    <Card
      flexDirection="column"
      w="100%"
      px="0px"
      overflowX={{
        sm: 'scroll',
        lg: 'hidden',
      }}
    >
      <Box>
        <Table variant="simple" color="gray.500" mb="24px" mt="12px" layout="auto">
          <Thead>
            {table.getHeaderGroups().map(headerGroup => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map(header => {
                  return (
                    <Th
                      key={header.id}
                      colSpan={header.colSpan}
                      pe="10px"
                      borderColor={borderColor}
                      cursor="pointer"
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      <Flex
                        justifyContent="space-between"
                        align="center"
                        fontSize={{
                          sm: '10px',
                          lg: '12px',
                        }}
                        color="gray.400"
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {{
                          asc: '',
                          desc: '',
                        }[header.column.getIsSorted() as string] ?? null}
                      </Flex>
                    </Th>
                  );
                })}
              </Tr>
            ))}
          </Thead>

          {loading ? (
            <TableLoader columnsLength={columns.length} />
          ) : !tableData.items?.length ? (
            <TableNoData columnsLength={columns.length} />
          ) : (
            <Tbody>
              {table.getRowModel().rows.map(row => {
                return (
                  <Tr
                    key={row.id}
                    onClick={() => navigateToUser(parseInt(row.getValue('ID')))}
                    backgroundColor={rowColors[row.getValue('gender') as Gender]}
                    cursor="pointer"
                    sx={{ '&:hover': { backgroundColor: rowColors[`${row.getValue('gender') as Gender}Hover`] } }}
                  >
                    {row.getVisibleCells().map(cell => {
                      return (
                        <Td
                          key={cell.id}
                          fontSize={{ sm: '14px' }}
                          minW={{
                            sm: '150px',
                            md: '200px',
                            lg: 'auto',
                          }}
                          borderColor="transparent"
                        >
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
            </Tbody>
          )}
        </Table>
      </Box>
      <Divider />
      <Flex justifyContent="space-between" alignItems="center" py={3} px="24px">
        <Box flexGrow={1}>
          <IconButton
            aria-label="First page"
            icon={<MdOutlineKeyboardDoubleArrowLeft />}
            onClick={() => changePage(1)}
            isDisabled={tableData.pagination?.currentPage === 1}
            size="lg"
          />
          <IconButton
            aria-label="prev page"
            icon={<MdOutlineKeyboardArrowLeft />}
            onClick={() => changePage(tableData.pagination?.prevPage)}
            isDisabled={!tableData.pagination?.prevPage}
            size="lg"
          />
          <Box display="inline-block" px={3} color="gray.400">
            {tableData.pagination?.currentPage}
          </Box>
          <IconButton
            aria-label="next page"
            icon={<MdOutlineKeyboardArrowRight />}
            onClick={() => changePage(tableData.pagination?.nextPage)}
            isDisabled={!tableData.pagination?.nextPage}
            size="lg"
          />
          <IconButton
            aria-label="Last page"
            icon={<MdOutlineKeyboardDoubleArrowRight />}
            onClick={() => changePage(tableData.pagination?.lastPage)}
            isDisabled={
              tableData.pagination?.lastPage && tableData.pagination?.currentPage === tableData.pagination?.lastPage
            }
            size="lg"
          />
        </Box>
        <Flex direction="row" align="center">
          <Text color="gray.400" fontSize="sm" fontWeight="700" pr={5} whiteSpace="nowrap">
            {t('common:items_on_page')}
          </Text>
          <Select
            sx={{ '> option': { color: 'gray.400' } }}
            value={perPage || 20}
            onChange={e => changePerPage(parseInt(e.target.value))}
          >
            {[10, 20, 30, 50, 100].map(item => (
              <option key={`items-per-page-${item}`} value={item} color="gray.400">
                {item}
              </option>
            ))}
          </Select>
        </Flex>
      </Flex>
    </Card>
  );
}
