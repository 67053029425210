import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Box, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { MdStop } from 'react-icons/md';

import { throwWarningToast } from '../../../shared/lib/toastify';
import ContextMenu, { IContextMenuItem } from '../../../components/menu/ContextMenu';
import userStore from '../../../stores/userStore';

import { UserHistoryTab, UserOffersTab, UserProfileTab } from './tabs';
import { UserBlockedTab } from './tabs/UserBlockedTab';
import { UserBlockedByTab } from './tabs/UserBlockedByTab';

export default function UserPage() {
  const [tabIndex, setTabIndex] = useState(0);
  const { id } = useParams();
  const { t } = useTranslation('translation');
  const { user, fetchUser } = userStore();

  useEffect(() => {
    //TODO investigate why we have 2 calls here
    fetchUser(parseInt(id));

    setTabIndex(0);
    window.scrollTo({ top: 0 });
  }, [id]);


  const menuItems: IContextMenuItem[] = [
    {
      label: t('user:menu.stop_search'),
      icon: MdStop,
      disabled: user.searcher?.searchStatus === 'active',
      onClick: () => {
        throwWarningToast('Пока не реaлизовано');
      },
    },
  ];

  const handleTabsChange = (index: number) => {
    setTabIndex(index)
  };

  return (
    <Box
      flexDirection="column"
      w="100%"
      overflowX={{
        sm: 'hidden',
        lg: 'hidden',
      }}
      mt={{
        base: '80px',
        md: '80px',
        xl: '80px',
      }}
    >
      <Tabs isLazy={true} variant="enclosed" index={tabIndex} onChange={handleTabsChange}>
        <TabList>
          <Tab>Профиль</Tab>
          <Tab>Пары</Tab>
          <Tab>История</Tab>
          <Tab>Заблокировали пользователя</Tab>
          <Tab>Пользователь заблокировал</Tab>
          <Box flexGrow={1} textAlign="right" pr={8}>
            <ContextMenu menuItems={menuItems} id="user-context-menu" />
          </Box>
        </TabList>
        <TabPanels>
          <TabPanel>
            <UserProfileTab user={user} />
          </TabPanel>
          <TabPanel>
            <UserOffersTab user={user} />
          </TabPanel>
          <TabPanel>
            <UserHistoryTab user={user} />
          </TabPanel>
          <TabPanel>
            <UserBlockedByTab user={user} />
          </TabPanel>
          <TabPanel>
            <UserBlockedTab user={user} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
}
