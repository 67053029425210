import { useEffect } from 'react';

import { Box, Button, Card, Flex, Table, Tbody, Td, Text, Th, Thead, Tr, useColorModeValue } from '@chakra-ui/react';
import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';

import { IUserEvent } from 'shared/model/offers';
import userStore from 'stores/userStore';
import TableLoader from 'shared/ui/Table/TableLoader';
import TableNoData from 'shared/ui/Table/TableNoData';
import { IUser } from 'shared/model';
import { formatDateTime } from 'shared/utils';

import { mapUserEvent } from '../utils';


const columnHelper = createColumnHelper<IUserEvent>();

interface IUserHistoryTabProps {
  user: IUser;
}

export const UserHistoryTab = ({ user }: IUserHistoryTabProps) => {
  const textColor = useColorModeValue('secondaryGray.900', 'gray.300');
  const { t } = useTranslation('translation');
  const { getUserEvents, userEvents, getUserEventsLoading: loading, unbanUser, unbanUserLoading } =
    userStore();
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
  const unbanButtonColor = useColorModeValue('red.500', 'white');
  const banButtonColor = useColorModeValue('red.600', 'white');

  useEffect(() => {
    if (user.userId) {
      getUserEvents(user.userId);
    }
  }, [user.userId]);


  const columns = [
    columnHelper.accessor('id', {
      id: 'ID',
      header: () => (
        <Box>
          <Text
            justifyContent="space-between"
            align="center"
            fontSize={{
              sm: '10px',
              lg: '12px',
            }}
            color="gray.400"
          >
            {t(`user:id`)}
          </Text>
        </Box>
      ),
      cell: info => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor('eventType', {
      id: 'eventType',
      header: () => (
        <Box>
          <Text
            justifyContent="space-between"
            align="center"
            fontSize={{
              sm: '10px',
              lg: '12px',
            }}
            color="gray.400"
          >
            {t(`user:events:eventType`)}
          </Text>
        </Box>
      ),
      cell: info => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {mapUserEvent(info.row.original, t)}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor('createdAt', {
      id: 'createdAt',
      header: () => (
        <Box>
          <Text
            justifyContent="space-between"
            align="center"
            fontSize={{
              sm: '10px',
              lg: '12px',
            }}
            color="gray.400"
          >
            {t(`user:events:eventDate`)}
          </Text>
        </Box>
      ),
      cell: info => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {formatDateTime(info.getValue())}
          </Text>
        </Flex>
      ),
    }),
  ];

  const table = useReactTable({
    data: userEvents.items,
    columns,
    state: {
      columnVisibility: { gender: false },
    },
    enableSorting: false,
    getCoreRowModel: getCoreRowModel(),
  });

  const handleUserUnban = () => {
    unbanUser(user.userId);
  };

  return user && (
    <Card
      flexDirection="column"
      w="100%"
      px="0px"
      overflowX={{
        sm: 'scroll',
        lg: 'hidden',
      }}
    >
      <Box>
        {user.searcher.searchStatus === 'ban' && (
          <Box display="flex" pr="5px" justifyContent="end" color={user.searcher.searchStatus === 'ban' ? unbanButtonColor : banButtonColor} fontWeight="bold" fontSize="2xl" mt="10px" mb="4px">
            <Button
              colorScheme={user.searcher.searchStatus === 'ban' ? 'red' : 'green'}
              onClick={handleUserUnban}
              isLoading={unbanUserLoading}
            >
              {t(user.searcher.searchStatus === 'ban' ? 'user:events:unban' : 'user:events:ban')}
            </Button>

          </Box>
        )}
        <Table variant="simple" color="gray.500" mb="24px" mt="12px" layout="auto">
          <Thead>
            {table.getHeaderGroups().map(headerGroup => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map(header => {
                  return (
                    <Th
                      key={header.id}
                      colSpan={header.colSpan}
                      pe="10px"
                      borderColor={borderColor}
                      cursor="pointer"
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      <Flex
                        justifyContent="space-between"
                        align="center"
                        fontSize={{
                          sm: '10px',
                          lg: '12px',
                        }}
                        color="gray.400"
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {{
                          asc: '',
                          desc: '',
                        }[header.column.getIsSorted() as string] ?? null}
                      </Flex>
                    </Th>
                  );
                })}
              </Tr>
            ))}
          </Thead>

          {loading ? (
            <TableLoader columnsLength={columns.length} />
          ) : !userEvents.items?.length ? (
            <TableNoData columnsLength={columns.length} />
          ) : (
            <Tbody>
              {table.getRowModel().rows.map(row => {
                return (
                  <Tr
                    key={row.id}
                    cursor="pointer"
                  >
                    {row.getVisibleCells().map(cell => {
                      return (
                        <Td
                          key={cell.id}
                          fontSize={{ sm: '14px' }}
                          minW={{
                            sm: '150px',
                            md: '200px',
                            lg: 'auto',
                          }}
                          borderColor="transparent"
                        >
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
            </Tbody>
          )}
        </Table>
      </Box>
    </Card>
  );
};