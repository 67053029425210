import { TFunction } from 'i18next';

import { IUserEvent } from 'shared/model/offers';

export const mapUserEvent = (event: IUserEvent, t: TFunction<'translation', undefined>) => {
  switch (event.eventType) {
    case 'blockedMe':
      return t('user:events:blockedMe', { username: event.candidateName });

    case 'tariffPurchased':
      return t('user:events:tariffPurchased', { tariffName: event.tariffName });

    default:
      return t(`user:events:${event.eventType}`);
  }
};
