// https://codesandbox.io/p/devbox/yet-another-react-lightbox-examples-9qvmif?file=%2Fsrc%2Fexamples%2FCarousel.tsx%3A15%2C1-16%2C23

import { useRef, useState } from 'react';

import Lightbox, { SlideImage } from 'yet-another-react-lightbox';
import Inline from 'yet-another-react-lightbox/plugins/inline';
import Zoom from 'yet-another-react-lightbox/plugins/zoom';
import Counter from 'yet-another-react-lightbox/plugins/counter';
import { AlertDialog, AlertDialogBody, AlertDialogCloseButton, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Box, Button, Flex, useDisclosure, useColorModeValue, Tag } from '@chakra-ui/react';

import 'yet-another-react-lightbox/styles.css';
import userStore from 'stores/userStore';

interface IUpdateIndexActionParams {
  index: number;
}

export type PhotoItem = SlideImage & {
  id: string;
  status: string;
};

export interface IPhotosWithLightboxProps {
  variant: 'album' | 'carousel';
  images: PhotoItem[];
}

const PhotosWithLightbox = ({ variant, images }: IPhotosWithLightboxProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = useRef();
  const currentRemovingPhotoId = useRef<string | null>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [index, setIndex] = useState<number>(0);

  const { deletePhoto, photoDeleteLoading } = userStore();

  const toggleOpen = (state: boolean) => () => setOpen(state);

  const updateIndex = (params: IUpdateIndexActionParams) => setIndex(params.index);

  const showDeleteModal = (id: string) => () => {
    currentRemovingPhotoId.current = id;
    onOpen();
  };

  const handleImageDelete = async () => {
    await deletePhoto(currentRemovingPhotoId.current);
    onClose();
    window.location.reload();
  };

  const lightboxBgColors = useColorModeValue('var(--chakra-colors-secondaryGray-300)', 'var(--chakra-colors-navy-900)');

  function renderPhotos(
    variant: 'album' | 'carousel',
    images: SlideImage[],
    updateIndex: (params: IUpdateIndexActionParams) => void
  ): JSX.Element {
    switch (variant) {
      case 'album':
        return <Box>album</Box>;
      case 'carousel':
        return (
          <Lightbox
            styles={{ container: { backgroundColor: lightboxBgColors } }}
            index={index}
            slides={images}
            render={{
              slide: ({ slide }) => {
                const customSlide = slide as PhotoItem;
                
                return (
                  <Box>
                    <img src={slide.src} alt={slide.alt} style={{ maxWidth: '100%', display: 'block' }} />
                    {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                    {/* @ts-ignore */}
                    <Flex paddingY="4" justifyContent="space-between" alignItems="center">
                      <Button colorScheme="blue" size="sm" onClick={showDeleteModal(customSlide.id)} isLoading={photoDeleteLoading}>
                        Удалить
                      </Button>
                      <Tag
                        variant='solid'
                        py={2} px={4} borderRadius={20}
                        colorScheme={
                          customSlide.status === 'active' ? 'green' :
                          customSlide.status === 'pending' ? 'yellow': 
                          'gray'
                        }
                      >
                        {customSlide.status}
                      </Tag>
                    </Flex>
                  </Box>
                )
              }
            }}
            plugins={[Inline, Counter]}
            on={{
              view: updateIndex,
              click: toggleOpen(true),
            }}
            carousel={{
              padding: 0,
              spacing: 0,
              imageFit: 'contain',
              finite: true,
            }}
            counter={{
              container: {
                style: {
                  position: 'absolute',
                  top: '10px',
                  left: '10px',
                  backgroundColor: '#fff',
                  color: '#222',
                  padding: '.05em .3em',
                  borderRadius: '.3em',
                },
              },
            }}
            inline={{
              style: {
                width: '100%',
                maxWidth: '900px',
                aspectRatio: '1 / 1',
                margin: '0 auto',
              },
            }}
          />
        );
    }
  }

  return (
    <Box>
      {renderPhotos(variant, images, updateIndex)}
      <Lightbox
        open={open}
        close={() => setOpen(false)}
        slides={images}
        on={{ view: updateIndex }}
        index={index}
        plugins={[Counter, Zoom]}
        counter={{
          container: {
            style: {
              position: 'absolute',
              top: '10px',
              left: '10px',
              fontWeight: '500',
              backgroundColor: '#fff',
              padding: '.05em .3em',
              borderRadius: '.3em',
            },
          },
        }}
        zoom={{ scrollToZoom: true }}
      />
      <AlertDialog
        motionPreset='slideInBottom'
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>Удалить фотографию?</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            Вы уверены, что хотите удалить это фото?
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              Нет
            </Button>
            <Button colorScheme='red' ml={3} onClick={handleImageDelete}>
              Да
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </Box>
  );
};

export default PhotosWithLightbox;
